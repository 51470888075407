<template>
  <div>
    <div class="bx-header">
      <h3 class="title">Détails du ticket</h3>
      <button
        @click="beginTreat"
        v-if="current.status == '0' && !is_mcp"
        :disabled="treatLoading"
        :class="
          treatLoading
            ? 'button is-loading is-primary is-small'
            : 'button is-primary is-small'
        "
      >
        Démarrer le traitement
      </button>
      <button
        @click="markTreat"
        v-if="current.status == '1' && !is_mcp"
        :disabled="markLoading"
        :class="
          markLoading
            ? 'button is-loading is-success is-small'
            : 'button is-success is-small'
        "
      >
        Marqué comme traité
      </button>
    </div>
    <mcp-spinner v-if="loading" :show="true" color="#CF0C1A" />
    <div class="box-flex" v-else>
      <div v-for="(message, key) in messages" :key="key" class="box-support">
        <h4>
          {{
            message.is_customer == "1"
              ? detail.lastname + " " + detail.firstname
              : "Support"
          }}
        </h4>
        <p class="content" v-html="message.content"></p>
        <span class="created">{{ format(message.created_at) }}</span>
      </div>
      <button
        v-if="!showForm && current.status != '2'"
        style="width: 150px"
        class="button is-dark is-small"
        @click="showAddForm"
      >
        Ajouter une réponse
      </button>
      <div class="pt-10" v-if="showForm && current.status != '2'">
        <text-area-input
          :errors="errors.content"
          v-model="form.content"
          label="Description"
        />
      </div>
      <div class="pt-10 d-flex-right" v-if="showForm && current.status != '2'">
        <button
          @click="sendResponse"
          :disabled="loadingBtn"
          :class="
            loadingBtn
              ? 'button is-responsive is-loading is-primary'
              : 'button is-responsive is-primary'
          "
        >
          Envoyer
        </button>
        <button
          class="button is-outlined is-responsive"
          @click="cancelOperation"
        >
          Annuler
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { TYPES } from "@/store/types";
import { mapGetters } from "vuex";
import TextAreaInput from "@/components/TextAreaInput";
import moment from "moment";
export default {
  name: "detailTicket",
  components: { TextAreaInput },
  props: {
    detail: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    loading: false,
    loadingBtn: false,
    showForm: false,
    treatLoading: false,
    markLoading: false,
    form: {
      content: "",
      ticket_id: "",
    },
    errors: [],
    current: {},
    is_mcp: null,
  }),

  computed: {
    ...mapGetters({
      messages: `${TYPES.modules.SUPPORTS}/${TYPES.getters.GET_SUPPORT}`,
    }),
  },

  mounted() {
    this.is_mcp = this.$isMcp();
    this.current = this.detail;
    this.getSupport();
  },

  methods: {
    format(date) {
      return moment(date).format("DD-MM-YYYY H:mm");
    },

    showAddForm() {
      this.showForm = !this.showForm;
    },

    markTreat() {
      this.markLoading = true;
      this.$store
        .dispatch(
          `${TYPES.modules.SUPPORTS + "/" + TYPES.actions.MARK_AS_TREAT}`,
          this.detail
        )
        .then(() => {
          this.markLoading = false;
          this.current.status = "2";
        })
        .catch(() => {
          this.markLoading = false;
        });
    },

    beginTreat() {
      this.treatLoading = true;
      this.$store
        .dispatch(
          `${TYPES.modules.SUPPORTS + "/" + TYPES.actions.BEGIN_TREAT}`,
          this.detail
        )
        .then(() => {
          this.treatLoading = false;
          this.current.status = "1";
        })
        .catch(() => {
          this.treatLoading = false;
        });
    },

    sendResponse() {
      this.loadingBtn = true;
      this.form.ticket_id = this.detail.id;
      this.$store
        .dispatch(
          `${TYPES.modules.SUPPORTS + "/" + TYPES.actions.SEND_SUPPORT}`,
          this.form
        )
        .then((re) => {
          this.errors = [];
          this.loadingBtn = false;
          this.form.content = "";
          this.$toast.success(`La réponse a été envoyé`);
          const model = re.ticket;
          this.$store.commit(
            `${TYPES.modules.SUPPORTS + "/" + TYPES.mutations.PUSH_MESSAGE}`,
            { support: model }
          );
          this.showAddForm();
        })
        .catch((error) => {
          this.loadingBtn = false;
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        });
    },

    cancelOperation() {
      this.showAddForm();
    },

    getSupport() {
      this.loading = true;
      this.$store
        .dispatch(
          `${TYPES.modules.SUPPORTS + "/" + TYPES.actions.DETAILS_SUPPORT}`,
          this.detail
        )
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.box-support {
  width: 100%;
  min-height: 100px;
  padding: 20px;
  border: 1px solid #fff;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 2rem 3rem rgba(132, 139, 200, 0.18);
}
.box-flex {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.title {
  font-size: 15px;
}
.content {
  padding-top: 10px;
}
.created {
  display: flex;
  justify-content: flex-end;
  font-style: italic;
  font-size: 10px;
}
.bx-header {
  display: flex;
  justify-content: space-between;
}
</style>