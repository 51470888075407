<template>
  <div class="box-content">
    <mcp-modal :show="show" @closeModal="showModal">
      <add-new-ticket
        @closeModal="showModal"
        :callBackFunction="refreshSupport"
      />
    </mcp-modal>
    <mcp-modal :show="showDetail" @closeModal="showModalDetail">
      <detail-ticket @closeModal="showModalDetail" :detail="support" />
    </mcp-modal>
    <search :makeSearch="makeSearch" :showSearch="false">
      <template #buttons>
        <button class="button is-outlined is-small" @click="showAddForm">
          Ajouter un ticket
        </button>
        <button @click="refreshSupport" class="button is-outlined is-small">
          Actualiser
        </button>
      </template>
    </search>
    <data-table
      :columns="columns"
      :loading="loading"
      :counter="supports.length"
      :meta="meta"
      :navigationFunction="navigationFunction"
    >
      <template #body>
        <tbody>
          <tr v-for="(item, key) in supports" :key="key">
            <td>{{ item.ref }}</td>
            <td>{{ item.lastname + " " + item.firstname }}</td>
            <td>{{ item.title }}</td>
            <td>
              <span v-if="item.status == '2'" class="tag is-success is-light"
                >Traité</span
              >
              <span
                v-else-if="item.status == '0'"
                class="tag is-primary is-light"
                >Soumit</span
              >
              <span v-else class="tag is-info is-light"
                >En cours de traitement</span
              >
            </td>
            <td>{{ item.created_at }}</td>
            <td>
              <div class="d-flex">
                <mcp-tooltip title="Détails">
                  <i @click="detailSupport(item)" class="fa-solid fa-info"></i>
                </mcp-tooltip>
                <mcp-tooltip title="Supprimer">
                  <i
                    v-if="item.status == '0'"
                    @click="removeSupport(item)"
                    class="fa-solid fa-remove"
                  ></i>
                </mcp-tooltip>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </data-table>
  </div>
</template>
<script>
import { TYPES } from "@/store/types";
import { mapGetters } from "vuex";
import dataTable from "@/components/table/datatable";
import search from "@/components/table/search";
import mcpModal from "@/components/McpModal";
import addNewTicket from "./add.vue";
import detailTicket from "./details.vue";
export default {
  name: "support",
  components: {
    dataTable,
    search,
    mcpModal,
    addNewTicket,
    detailTicket,
  },
  data: () => ({
    columns: [
      { name: "ref", label: "N°" },
      { name: "firstname", label: "Client" },
      { name: "title", label: "Titre" },
      { name: "status", label: "Statut" },
      { name: "created_at", label: "Date de création" },
      { name: "action", label: "Actions" },
    ],
    model: {
      page: 1,
      search: "",
    },
    show: false,
    showDetail: false,
    loading: false,
    support: {},
  }),

  computed: {
    ...mapGetters({
      supports: `${TYPES.modules.SUPPORTS}/${TYPES.getters.GET_SUPPORTS}`,
      meta: `${TYPES.modules.SUPPORTS}/${TYPES.getters.GET_SUPPORTS_META}`,
    }),
  },

  mounted() {
    this.getSupports();
  },

  methods: {
    navigationFunction(page) {
      this.model.page = page;
      this.getSupports();
    },

    getSupports() {
      this.loading = true;
      this.$store
        .dispatch(
          `${TYPES.modules.SUPPORTS + "/" + TYPES.actions.GET_SUPPORTS}`,
          this.model
        )
        .then((res) => {
          if (res) this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },

    makeSearch() {
      this.model.page = 1;
      this.loading = true;
      this.$store
        .dispatch(
          `${TYPES.modules.SUPPORTS + "/" + TYPES.actions.GET_SUPPORTS}`,
          this.model
        )
        .then((res) => {
          if (res) this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },

    refreshSupport() {
      this.loading = true;
      this.model.page = 1;
      this.$store
        .dispatch(
          `${TYPES.modules.SUPPORTS + "/" + TYPES.actions.GET_SUPPORTS}`,
          this.model
        )
        .then((res) => {
          if (res) this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },

    removeSupport(support) {
      if (confirm("Voulez vous supprimer ?")) {
        this.loading = true;
        this.$store
          .dispatch(
            `${TYPES.modules.SUPPORTS + "/" + TYPES.actions.DELETE_SUPPORT}`,
            support
          )
          .then((res) => {
            if (res) {
              this.loading = false;
              this.$toast.success(`Opération éffectuée`);
            }
          })
          .catch((error) => {
            this.loading = false;
          });
      }
    },

    showModal() {
      this.show = !this.show;
    },
    showModalDetail() {
      this.showDetail = !this.showDetail;
    },

    detailSupport(support) {
      this.support = support;
      this.showModalDetail();
    },

    showAddForm() {
      this.showModal();
    },
  },
};
</script>